import {useEffect, useRef} from "react";
import useBrowserType from "./useBrowserType";
import mixpanel from "mixpanel-browser";

export default function useSendToStore(target:string){
    const buttonRef = useRef<HTMLElement>(null)
    const {browser,url} = useBrowserType()
    return{
        buttonRef,browser,url
    }
}