import React, {useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import {useInView} from 'framer-motion';

import {Container} from './Container';
import {reviews} from '../constants';
import Chain from "./Chain";
import {Link} from "react-router-dom";
import PersonFallback from '../images/person-fallback.jpeg'

function StarIcon(props: any) {
    return (
        <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
            <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"/>
        </svg>
    );
}

function StarRating({rating}: any) {
    return (
        <div className="flex">
            {[0, 1, 2, 3, 4].map((index) => (
                <StarIcon
                    key={index}
                    className={clsx(
                        'h-5 w-5',
                        rating > index ? 'fill-[#2D113E]' : 'fill-gray-300'
                    )}
                />
            ))}
        </div>
    );
}

function Review({title, body, author, rating, className, url,profilepicture, id, ...props}: any) {
    let animationDelay = useMemo(() => {
        let possibleAnimationDelays = [
            '0s',
            '0.1s',
            '0.2s',
            '0.3s',
            '0.4s',
            '0.5s',
        ];
        return possibleAnimationDelays[
            Math.floor(Math.random() * possibleAnimationDelays.length)
            ];
    }, []);

    const sendToPHunt = (url:string) => {
        window.open(url,"_blank","noreferrer");
    }
    return (
        <figure
            className={clsx(
                'animate-fade-in rounded-3xl bg-white p-6 opacity-0 shadow-md shadow-gray-900/5',
                className
            )}
            style={{animationDelay}}
            {...props}
        >
            <blockquote className="text-gray-900">
                <StarRating rating={rating}/>
                <p className="mt-4 text-lg font-semibold leading-6 before:content-['“'] after:content-['”']">
                    {title}
                </p>
                <p className="mt-3 text-base leading-7">{body}</p>
            </blockquote>
            <figcaption className="mt-3 text-sm grid gap-1 text-gray-600">
                <div className={'grid w-fit h-fit grid-flow-col gap-1'}>
                    <div className={'grid overflow-hidden border border-solid border-gray-200 rounded-full'}>
                        {
                            profilepicture ? (
                                <img className={'w-6 h-6'} src={profilepicture}/>
                            ) : (
                                <img className={'w-6 h-6'} src={PersonFallback}/>
                            )
                        }
                    </div>
                    <div className={'grid font-bold'}>
                        {author}
                    </div>
                    {
                        id === "producthunt" && (
                            <> via
                            <div onClick={() => {sendToPHunt(url)}} className={'grid place-self-center cursor-pointer place-content-center w-fit h-fit text-gray-700'}>
                                <svg className="logo_x0D50" width="20" height="20" viewBox="0 0 40 40"
                                     xmlns="http://www.w3.org/2000/svg" aria-describedby="logoTitle" role="img"><title
                                    id="logoTitle">Product Hunt logo</title>
                                    <g fill="none" fill-rule="evenodd">
                                        <path
                                            d="M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20"
                                            fill="#DA552F"></path>
                                        <path
                                            d="M22.667 20H17v-6h5.667c1.656 0 3 1.343 3 3s-1.344 3-3 3m0-10H13v20h4v-6h5.667c3.866 0 7-3.134 7-7s-3.134-7-7-7"
                                            fill="#FFF"></path>
                                    </g>
                                </svg>
                            </div></>
                        )
                    }
                </div>
            </figcaption>
        </figure>
    );
}

function splitArray(array: any[], numParts: number) {
    let result: any[] = [];
    for (let i = 0; i < array.length; i++) {
        let index = i % numParts;
        if (!result[index]) {
            result[index] = [];
        }
        result[index].push(array[i]);
    }
    return result;
}

function ReviewColumn({
                          className,
                          reviews,
                          reviewClassName = () => {
                          },
                          msPerPixel = 0,
                      }: any) {
    let columnRef = useRef<HTMLDivElement>(null);
    let [columnHeight, setColumnHeight] = useState(0);
    let duration = `${columnHeight * msPerPixel}ms`;

    useEffect(() => {
        let resizeObserver = new window.ResizeObserver(() => {
            setColumnHeight(columnRef.current?.offsetHeight || 0);
        });

        if (columnRef.current) {
            resizeObserver.observe(columnRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [columnRef]);

    return (
        <div
            ref={columnRef}
            className={clsx('animate-marquee space-y-8 py-4', className)}
            style={{'--marquee-duration': duration} as React.CSSProperties}
        >
            {reviews.concat(reviews).map((review: any, reviewIndex: number) => (
                    <Review
                        key={reviewIndex}
                        aria-hidden={reviewIndex >= reviews.length}
                        className={reviewClassName(reviewIndex % reviews.length)}
                        {...review}
                    />
                )
            )}
        </div>
    );
}

function ReviewGrid() {
    let containerRef = useRef<HTMLDivElement>(null);
    let isInView = useInView(containerRef, {once: true, amount: 0.4});
    const shuffle = (array: any[]) => {
        return array.sort(() => Math.random() - 0.5);
    };
    const reviewsArray = shuffle(reviews)
    let columns = splitArray(reviewsArray, 3);
    columns = [columns[0], columns[1], splitArray(columns[2], 2)];
    return (
        <div
            ref={containerRef}
            className="relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3"
        >
            {isInView && (
                <>
                    <ReviewColumn
                        reviews={[...columns[0], ...columns[2].flat(), ...columns[1]]}
                        reviewClassName={(reviewIndex: number) =>
                            clsx(
                                reviewIndex >= columns[0].length + columns[2][0].length &&
                                'md:hidden',
                                reviewIndex >= columns[0].length && 'lg:hidden'
                            )
                        }
                        msPerPixel={10}
                    />
                    <ReviewColumn
                        reviews={[...columns[1], ...columns[2][1]]}
                        className="hidden md:block"
                        reviewClassName={(reviewIndex: number) =>
                            reviewIndex >= columns[1].length && 'lg:hidden'
                        }
                        msPerPixel={15}
                    />
                    <ReviewColumn
                        reviews={columns[2].flat()}
                        className="hidden lg:block"
                        msPerPixel={10}
                    />
                </>
            )}
            <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-50"/>
            <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-50"/>
        </div>
    );
}

export function Reviews() {
    return (
        <section
            id="reviews"
            aria-labelledby="reviews-title"
            className="pb-16 pt-20 sm:pb-24 sm:pt-32"
        >
            <Container>
                <div className={'grid'}>
                    <div className={'grid grid-flow-col w-fit gap-[4px] place-self-center'}>
                        <Chain/>
                        <Link to={'#reviews'}>
                            <h2
                                id="reviews-title"
                                className="text-3xl font-medium tracking-tight text-gray-900 sm:text-center"
                            >
                                Thrilled Users Experience Unparalleled Productivity Boost with Torq!
                            </h2>
                        </Link>
                    </div>
                </div>
                <p className="mt-2 text-lg text-gray-600 sm:text-center">
                    Discover What Users Have to Say About Torq's Powerful Features!
                </p>
                <ReviewGrid/>
            </Container>
        </section>
    );
}
