import {useEffect, useState} from "react";
import {detect} from "detect-browser";

export default function useBrowserType(){
    const [browser, setBrowser] = useState('');
    const [url, setUrl] = useState(
        'https://chrome.google.com/webstore/detail/hjpbafkbdcdlojmhaklhfbdighpdpghn?authuser=1&hl=en'
    );
    useEffect(() => {
        const browserInfo = detect();
        const detectedBrowser = browserInfo ? browserInfo.name : '';
        setBrowser(detectedBrowser);
    }, []);
    useEffect(() => {
        if (browser === 'edge-chromium') {
            setUrl(
                'https://microsoftedge.microsoft.com/addons/detail/torq-unleash-ai-empower/cildgfbbnnheahnengnlkidhhabgbghg'
            );
        }
    }, [browser]);

    return {browser,url}
}