export const SCORE_MAP = new Map<number, { state:string,emoji:string }>(new Map([
    [0,{state:'',emoji:''}],
    [1, { state: "Dissatisfied", emoji: "😞" }],
    [2, { state: "Unhappy", emoji: "😕" }],
    [3, { state: "Neutral", emoji: "😐" }],
    [4, { state: "Okay", emoji: "🙂" }],
    [5, { state: "Satisfied", emoji: "😊" }],
    [6, { state: "Pleased", emoji: "😄" }],
    [7, { state: "Delighted", emoji: "😁" }],
    [8, { state: "Happy", emoji: "😉" }],
    [9, { state: "Very Happy", emoji: "🤩" }],
    [10, { state: "Extremely Happy", emoji: "🎉" }]
]))