export const faqs = [
    {
        id: 'what_is_torq',
        question: 'What is Torq?',
        answer:
            'The Torq Chrome Extension is a powerful tool that combines AI capabilities and a Smart Reply feature for Gmail to enhance your browsing experience, streamline your workflow, and increase productivity.',
    },
    {
        id: 'smart_reply',
        question: 'What is Smart Reply for Gmail?',
        answer:
            "The Smart Reply for Gmail feature offers intelligent suggestions for responding to emails in Gmail. It's designed to help you draft replies quickly and efficiently.",
    },
    {
        id: 'text_summarisation',
        question: 'How does Text Summarisation work?',
        answer:
            'The Text Summarisation feature of Torq condenses the selected text from any webpage into a brief, understandable summary. This gives you a quick understanding of key points without reading through lengthy text.',
    },
    {
        id: 'grammar_correction',
        question: 'How does Grammar Correction work?',
        answer:
            'The Grammar Correction feature identifies grammatical errors in the selected text on any webpage and suggests corrections, improving your writing experience.',
    },
    {
        id: 'change_text_formatting',
        question: 'Can I change the formatting of text with Torq?',
        answer:
            'Yes, Torq allows you to customise the appearance of the selected text on any webpage, including changing text size, adjusting line spacing, and tweaking colour contrast.',
    },
    {
        id: 'text_simplification',
        question: 'What does Text Simplification do?',
        answer:
            'The Text Simplification feature rewrites the selected text on any webpage into simpler language, making complex information easier to understand.',
    },
    {
        id: 'content_discovery',
        question: 'What is Content Discovery?',
        answer:
            "The Content Discovery feature suggests related content based on the selected text from the webpage you're viewing. This allows you to explore related topics and discover new information.",
    },
    {
        id: 'provide_feedback',
        question: 'How to provide feedback for Torq?',
        answer:
            'You can provide feedback and suggestions through the settings tab in the extension. Your input helps us improve Torq further.',
    },
    {
        id: 'update_torq',
        question: 'How to update Torq?',
        answer:
            'To update Torq, simply navigate to the extensions page on your Chrome browser, locate Torq, and click on the "Update" button. If you have enabled auto-updates for extensions, Torq will update automatically.',
    },

    {
        id: 'session_expired',
        question: 'When the session expires error occur?',
        answer:
            'The "Session expires" error occurs when the user\'s session or login credentials have timed out.',
    },
    {
        id: 'unauthorized',
        question: 'When does the torq login get visible?',
        answer:
            'The "Torq login error" becomes visible when there is an issue with the Torq login process.',
    },
    {
        id: 'too_many_request',
        question: 'When the rate limit is reached error comes?',
        answer:
            'The "Rate limit reached" error occurs when the maximum number of requests allowed within a specified time period has been exceeded.',
    },
    {
        id: 'general_error',
        question: 'When does general error get visible? ',
        answer:
            'The "General error" becomes visible when an unspecified error or issue has occurred.',
    },

];