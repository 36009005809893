import {useState} from 'react';
import clsx from 'clsx';
import { Button } from './Button';
import { Container } from './Container';
import TorqGoogleIcon from './TorqGoogleIcon';
import useSendToStore from "../hooks/useSendToStore";
import Chain from "./Chain";
import {Link} from "react-router-dom";

const plans = [
  {
    name: 'Free',
    featured: false,
    price: { Monthly: '$0 🎉', Annually: '$0' },
    description: 'Unlock the Power of Our Product, Absolutely Free of Charge!',
    button: {
      label: 'Get started for free'
    },
    features: [
      'Rapid text processing for efficient results',
      'ChatGPT responses integrated with Google searches',
      'Swift email generation for effective communication',
      'Flexible search management: auto or manual control.',
    ],
    logomarkClassName: 'fill-gray-300',
  }
];

function CheckIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        fill="currentColor"
      />
      <circle
        cx="12"
        cy="12"
        r="8.25"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function Plan({
  name,
  price,
  description,
  button,
  features,
  featured = false,
  activePeriod,
  logomarkClassName,
}: any) {
  const {buttonRef,url} = useSendToStore('pricing')
  return (
    <section
      className={clsx(
        'flex flex-col overflow-hidden rounded-3xl p-6 shadow-lg shadow-gray-900/5',
        featured ? 'order-first bg-gray-900 lg:order-none' : 'bg-white'
      )}
    >
      <h3
        className={clsx(
          'flex gap-2 items-center text-sm font-semibold',
          featured ? 'text-white' : 'text-gray-900'
        )}
      >
        <TorqGoogleIcon
          className={clsx('h-10 w-10 flex-none', logomarkClassName)}
          style={{ width: 'auto' }}
        ></TorqGoogleIcon>
        <span className="mr-4">{name}</span>
      </h3>
      <p
        className={clsx(
          'relative mt-5 flex text-3xl tracking-tight',
          featured ? 'text-white' : 'text-gray-900'
        )}
      >
        {price.Monthly === price.Annually ? (
          price.Monthly
        ) : (
          <>
            <span
              aria-hidden={activePeriod === 'Annually'}
              className={clsx(
                'transition duration-300',
                activePeriod === 'Annually' &&
                  'pointer-events-none translate-x-6 select-none opacity-0'
              )}
            >
              {price.Monthly}
            </span>
            <span
              aria-hidden={activePeriod === 'Monthly'}
              className={clsx(
                'absolute left-0 top-0 transition duration-300',
                activePeriod === 'Monthly' &&
                  'pointer-events-none -translate-x-6 select-none opacity-0'
              )}
            >
              {price.Annually}
            </span>
          </>
        )}
      </p>
      <p
        className={clsx(
          'mt-3 text-sm',
          featured ? 'text-gray-300' : 'text-gray-700'
        )}
      >
        {description}
      </p>
      <div className="order-last mt-6">
        <ul
          role="list"
          className={clsx(
            '-my-2 divide-y text-sm',
            featured
              ? 'divide-gray-800 text-gray-300'
              : 'divide-gray-200 text-gray-700'
          )}
        >
          {features.map((feature: any) => (
            <li key={feature} className="flex py-2">
              <CheckIcon
                className={clsx(
                  'h-6 w-6 flex-none',
                  featured ? 'text-white' : 'text-[#2D113E]'
                )}
              />
              <span className="ml-4">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      <Button
        ref={buttonRef}
        href={url}
        target={'_blank'}
        color={'torqSolid'}
        className="mt-6"
        aria-label={`Get started with the ${name} plan for ${price}`}
      >
        {button.label}
      </Button>
    </section>
  );
}

export function Pricing() {
  let [activePeriod, setActivePeriod] = useState('Monthly');

  return (
    <section
      id="pricing"
      aria-labelledby="pricing-title"
      className="border-t border-gray-200 bg-gray-100 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <div className={'grid'}>
            <div className={'grid grid-flow-col gap-[4px]'}>
              <Chain/>
              <Link to={'#pricing'}>
                <h2
                    id="pricing-title"
                    className="text-3xl font-medium tracking-tight text-gray-900"
                >
                  Experience Our Product for Free: No Strings Attached!
                </h2>
              </Link>
            </div>
          </div>
          <p className="mt-2 text-lg text-gray-600">
            Whether you’re one person trying to get ahead or a big firm trying
            to take over the world, we are no one to stop you.
          </p>
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-10 sm:mt-20 lg:max-w-none lg:grid-cols-3 ">
          <div></div>
          {plans.map((plan) => (
            <Plan key={plan.name} {...plan} activePeriod={activePeriod} />
          ))}
          <div></div>
        </div>
      </Container>
    </section>
  );
}
