import designerdailyreport from "../images/designerdailyicon.png";
import React from "react";

export default function IndividualFeaturedButton({href,src,companyfeatured,width}:{href:string,src:string,companyfeatured:string,width:string}){
    return(
        <div className="featured blur-[0.4px] transition hover:blur-0 hover:grayscale-0" style={{
            width: width,
            borderRadius: "10px",
            border: "0.8px solid gray",
            textAlign: "center",
            userSelect: "auto",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        }}>
        <a href={href} className="block"
           style={{width: width, height: "100%"}} target="_blank">
            <div className={'grid h-full grid-cols-[auto_1fr] gap-1.5'}>
                <div className={'grid'}>
                    <img className={'w-10 h-12 py-2 pl-2 place-self-center'} src={src}/>
                </div>
                <div className={'grid'}>
                    <div className={'grid-rows-[auto_1fr] py-2 pr-2 grid'}>
                        <div className={'grid text-gray-700 text-[12px] self-end justify-self-start'}>
                            Featured on
                        </div>
                        <div className={'grid font-extrabold self-start justify-self-start text-[18px]'}>
                            {companyfeatured}
                        </div>
                    </div>
                </div>
            </div>
        </a>
        </div>
    )
}