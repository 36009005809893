import ScrollableFeaturedOn from "./ScrollableFeature";
import React, {useRef} from "react";

export default function FeaturedOn() {
    const parentRef = useRef<HTMLDivElement>(null);

    return (
        <div className={'py-4 grid'}>
            <div className={'grid max-w-[68.5rem] scrollwrapper mx-auto relative'}>
                <div className={'grid max-w-[68.5rem] scrollwrapperinside mx-auto relative'}>
                    <div ref={parentRef} className={'grid scroll-smooth relative max-w-[68.5rem] overflow-hidden h-16'}>
                        <ScrollableFeaturedOn parentRef={parentRef} className={''}/>
                    </div>
                </div>
            </div>

        </div>
    )
}