import React from 'react';

export default function TorqGoogleIcon({ className }: any, ...props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="24"
        height="24"
        fill="url(#paint0_linear_489_1034)"
        rx="5.75"
      ></rect>
      <path
        fill="url(#paint1_linear_489_1034)"
        d="M12.456 5.245a.206.206 0 00.205-.206V3.673a.206.206 0 00-.205-.205.206.206 0 00-.206.205v1.366c0 .113.092.206.206.206z"
      ></path>
      <path
        fill="url(#paint2_linear_489_1034)"
        d="M18.795 9.978h-1.367a.206.206 0 00-.205.206c0 .113.092.205.205.205h1.367a.206.206 0 00.205-.205.206.206 0 00-.205-.206z"
      ></path>
      <path
        fill="url(#paint3_linear_489_1034)"
        d="M7.352 10.184a.206.206 0 00-.206-.206H5.78a.206.206 0 00-.206.206c0 .113.093.205.206.205h1.366a.206.206 0 00.206-.205z"
      ></path>
      <path
        fill="url(#paint4_linear_489_1034)"
        d="M16.165 6.591l1.065-1.065a.209.209 0 000-.291.209.209 0 00-.291 0L15.873 6.3a.209.209 0 000 .291.209.209 0 00.292 0z"
      ></path>
      <path
        fill="url(#paint5_linear_489_1034)"
        d="M8.402 6.59a.211.211 0 00.144.062.206.206 0 00.206-.206.192.192 0 00-.062-.144L7.625 5.237a.209.209 0 00-.291 0 .206.206 0 000 .291L8.402 6.59z"
      ></path>
      <path
        fill="url(#paint6_linear_489_1034)"
        d="M14.9 11.622a.44.44 0 10-.884 0 .446.446 0 00.445.445c.24 0 .438-.199.438-.445z"
      ></path>
      <path
        fill="url(#paint7_linear_489_1034)"
        d="M10.46 11.31a.44.44 0 00-.626 0 .44.44 0 000 .627.446.446 0 00.63 0 .45.45 0 00-.003-.627z"
      ></path>
      <path
        fill="#fff"
        fillOpacity="0.41"
        d="M14.07 18.744h-.51a.206.206 0 01-.206-.206c0-.113.092-.205.205-.205h.51v-.822h-3.561v.822h2.342c.113 0 .206.092.206.205a.206.206 0 01-.206.206h-2.342v.079a.46.46 0 00.455.469H13.603a.458.458 0 00.466-.46v-.088z"
      ></path>
      <path
        fill="url(#paint8_linear_489_1034)"
        d="M12.321 6.453h-.034a4.232 4.232 0 00-4.199 3.696 2.58 2.58 0 00-.013.25 4.07 4.07 0 01-.268 1.339l-.62 1.63h1.33a.21.21 0 01.208.205v1.487a.794.794 0 00.795.801h.027l.004.003h.335a.608.608 0 01.62.6v.637h.753v-3.202a.447.447 0 00-.438-.456h-.007a.86.86 0 01-.856-.86v-.133a.769.769 0 01-.407-.226.85.85 0 01-.007-1.206.85.85 0 011.459.6.83.83 0 01-.244.603.917.917 0 01-.39.226v.126a.447.447 0 00.438.456h.007a.86.86 0 01.856.86V17.1h.411v-6.723a.96.96 0 01-.39-.23h.003a.855.855 0 111.21-.003l-.004.004a.758.758 0 01-.404.226V17.1h.445v-3.202a.86.86 0 01.85-.867h.006a.447.447 0 00.445-.448v-.134a.858.858 0 01-.39-1.435.854.854 0 011.205-.02.854.854 0 01.021 1.205l-.02.02a.807.807 0 01-.405.227v.126a.86.86 0 01-.849.867h-.007a.447.447 0 00-.445.448v3.213h.72v-.836c0-.931.403-1.835 1.099-2.48a4.236 4.236 0 00.212-5.982 4.26 4.26 0 00-3.062-1.35z"
      ></path>
      <path
        fill="url(#paint9_linear_489_1034)"
        d="M12.614 9.234a.44.44 0 00-.627 0 .44.44 0 000 .627.447.447 0 00.627 0 .447.447 0 000-.627z"
      ></path>
      <path
        fill="#7B7B7B"
        d="M12.082 20.05c0 .112.092.205.205.205a.206.206 0 00.206-.206v-.551h-.411v.551z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_489_1034"
          x1="12"
          x2="27.062"
          y1="-1.929"
          y2="18.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D113E"></stop>
          <stop offset="1" stopColor="#523366"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_489_1034"
          x1="12.456"
          x2="12.456"
          y1="3.468"
          y2="6.199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_489_1034"
          x1="18.111"
          x2="18.111"
          y1="9.978"
          y2="10.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_489_1034"
          x1="6.463"
          x2="6.463"
          y1="9.978"
          y2="10.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_489_1034"
          x1="16.552"
          x2="16.552"
          y1="5.176"
          y2="7.442"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_489_1034"
          x1="8.013"
          x2="8.013"
          y1="5.178"
          y2="7.443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_489_1034"
          x1="14.457"
          x2="14.457"
          y1="11.18"
          y2="12.543"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_489_1034"
          x1="10.146"
          x2="10.146"
          y1="11.179"
          y2="12.544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_489_1034"
          x1="11.852"
          x2="11.852"
          y1="6.453"
          y2="22.819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_489_1034"
          x1="12.3"
          x2="12.3"
          y1="9.103"
          y2="10.465"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
