import React, {useId} from 'react';
import {AppStoreLink} from './AppStoreLink';
import {Button} from './Button';
import {Container} from './Container';
import torqVideo from '../videos/torq_hero.mp4';

function BackgroundIllustration(props: any) {
    let id = useId();

    return (
        <div {...props}>
            <svg
                viewBox="0 0 1026 1026"
                fill="none"
                aria-hidden="true"
                className="absolute inset-0 h-full w-full animate-spin-slow"
            >
                <path
                    d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
                    stroke="#D4D4D4"
                    strokeOpacity="0.7"
                />
                <path
                    d="M513 1025C230.23 1025 1 795.77 1 513"
                    stroke={`#2D113E`}
                    strokeLinecap="round"
                />
                <defs>
                    <linearGradient
                        id={`${id}-gradient-1`}
                        x1="1"
                        y1="513"
                        x2="1"
                        y2="1025"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#06b6d4"/>
                        <stop offset="1" stopColor="#06b6d4" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
            <svg
                viewBox="0 0 1026 1026"
                fill="none"
                aria-hidden="true"
                className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
            >
                <path
                    d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
                    stroke="#D4D4D4"
                    strokeOpacity="0.7"
                />
                <path
                    d="M913 513c0 220.914-179.086 400-400 400"
                    stroke={`#2D113E`}
                    strokeLinecap="round"
                />
                <defs>
                    <linearGradient
                        id={`${id}-gradient-2`}
                        x1="913"
                        y1="513"
                        x2="913"
                        y2="913"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#564063"/>
                        <stop offset="1" stopColor="#564063" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}

function PlayIcon(props: any) {
    return (
        <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
            <circle cx="12" cy="12" r="11.5" stroke="#D4D4D4"/>
            <path
                d="M9.5 14.382V9.618a.5.5 0 0 1 .724-.447l4.764 2.382a.5.5 0 0 1 0 .894l-4.764 2.382a.5.5 0 0 1-.724-.447Z"
                fill="#7e5e91"
                stroke="#7e5e91"
            />
        </svg>
    );
}

export function Hero() {
    return (
        <div className="overflow-hidden py-20 sm:pb-32 sm:pt-16 lg:pb-32 xl:pb-16">
            <Container>
                <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
                    <div className="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
                        <h1 className="text-4xl font-medium tracking-tight text-gray-900">
                            Your Ultimate Productivity Assistant.
                        </h1>
                        <p className="mt-6 text-lg text-gray-600">
                            Harnessing the prowess of ChatGPT to revolutionize your
                            productivity through advanced AI assistance.
                        </p>
                        <div className="mt-8 flex flex-wrap gap-x-6 gap-y-4">
                            <AppStoreLink target={'main'} color="torqSolid"/>

                            <Button
                                href="https://www.youtube.com/watch?v=w7gaHaRhPYs"
                                variant="outline"
                            >
                                <PlayIcon className="h-6 w-6 flex-none"/>
                                <span className="ml-2.5">Watch the video</span>
                            </Button>
                        </div>
                        <div className="mt-8 flex flex-wrap">
                            <a rel={"noreferrer"}
                               href="https://www.producthunt.com/posts/torq-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-torq&#0045;ai"
                               target="_blank"><img
                                src={`https://api.producthunt.com/widgets/embed-image/v1/featured.svg?${performance.now()}&post_id=401420&theme=light`}
                                alt="Torq&#0032;AI - Your&#0032;Ultimate&#0032;Productivity&#0032;Assistant | Product Hunt"
                                width="300" height="54"/></a>
                        </div>
                    </div>
                    <div
                        className="relative hidden md:block mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
                        <BackgroundIllustration
                            className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0"/>
                        <div
                            className="-mx-4 px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32">
                            <div
                                className="border relative border-gray-200 drop-shadow-lg rounded-bl-lg rounded-br-lg rounded-tl-lg rounded-tr-lg bg-gray-100 relative">
                                <div className="py-2 px-2 bg-gray-200 rounded-tl-md rounded-tr-md flex items-center">
                                    <div className="rounded-full h-3 w-3 bg-red-400 flex mr-2"></div>
                                    <div className="rounded-full h-3 w-3 bg-yellow-400 flex mr-2"></div>
                                    <div className="rounded-full h-3 w-3 bg-green-400 flex mr-2"></div>
                                </div>
                                <div className="bg-white max-h-fit rounded-bl-md rounded-br-md relative">
                                    <div className="px-1 grid py-1">
                                        <div className={'grid'}>
                                            <video loop autoPlay muted className={'w-full h-full'}>
                                                <source src={torqVideo} type={'video/mp4'}/>
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
