import {EnvelopeIcon} from '@heroicons/react/24/outline'
import React, {useState} from "react";
import {INFOBJECT} from "../types";
import mixpanel from "mixpanel-browser";
import axios from "axios";


interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
}

const initialFormData: FormData = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
};
const Contact = ({setInfoObject}:{setInfoObject: React.Dispatch<React.SetStateAction<INFOBJECT>>}) => {
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [errors, setErrors] = useState<Partial<FormData>>({});

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const {name, value} = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const validationErrors: Partial<FormData> = {};

        // Validate required fields
        Object.keys(formData).forEach((key) => {
            if (!formData[key as keyof FormData]) {
                validationErrors[key as keyof FormData] = 'This field is required';
            }
        });

        // Add additional validations for specific fields (e.g., email)
        if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
            validationErrors.email = 'Invalid email address';
        }

        // If there are errors, set them in the state and stop form submission
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        // If no errors, submit the form
        // Here, you can handle form submission, e.g., make an API call

        axios.post("https://49ac09bnu8.execute-api.us-east-1.amazonaws.com/prod/contact-us", {
            "email_id": formData.email,
            "first_name":formData.firstName,
            "last_name":formData.lastName,
            "message":formData.message
        }, {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        })
            .then(
                (res) => {
                    console.log(res);
                    mixpanel.track("Contact",{...formData})
                    setInfoObject({
                        info:"Details submitted! We will contact you soon!",
                        redirectTime:0
                    })
                    setFormData(initialFormData);
                })
            .catch(
                (err) =>{
                    // Reset form data and errors
                    setFormData(initialFormData);
                    setErrors({});
                }
            )
    };
    return (
        <div className="relative isolate bg-white">
            <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
                    <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                        <div
                            className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                            <svg
                                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                                        width={200}
                                        height={200}
                                        x="100%"
                                        y={-1}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <path d="M130 200V.5M.5 .5H200" fill="none"/>
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" strokeWidth={0} fill="white"/>
                                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0}/>
                                </svg>
                                <rect width="100%" height="100%" strokeWidth={0}
                                      fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"/>
                            </svg>
                        </div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            We'd love to hear from you! Whether you have questions, feedback, or just want to say hello,
                            please don't hesitate to get in touch. You can reach us through the following channels:
                        </p>
                        <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">


                            <div className="flex gap-x-4">
                                <dt className="flex-none">
                                    <span className="sr-only">Email</span>
                                    <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true"/>
                                </dt>
                                <dd>
                                    <a className="hover:text-gray-900" href="mailto:connect@torq.live">
                                        connect@torq.live
                                    </a>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
                <form onSubmit={handleSubmit}
                      className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
                    <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="first-name"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    First name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        autoComplete="given-name"
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ${errors.firstName ? "ring-red-400":"ring-gray-300"}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2D113E] sm:text-sm sm:leading-6`}
                                    />
                                    {errors.firstName && <span className={"text-red-500 text-[12px]"}><sup>*</sup>{errors.firstName}</span>}
                                </div>
                            </div>
                            <div>
                                <label htmlFor="last-name"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Last name
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        id="lastName"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        autoComplete="family-name"
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ${errors.lastName ? "ring-red-400":"ring-gray-300"}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2D113E] sm:text-sm sm:leading-6`}
                                    />
                                    {errors.lastName && <span className={"text-red-500 text-[12px]"}><sup>*</sup>{errors.lastName}</span>}

                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                    Email
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        autoComplete="email"
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ${errors.email ? "ring-red-400":"ring-gray-300"}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2D113E] sm:text-sm sm:leading-6`}
                                    />
                                    {errors.email && <span className={"text-red-500 text-[12px]"}><sup>*</sup>{errors.email}</span>}

                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="message"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Message
                                </label>
                                <div className="mt-2.5">
                  <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={4}
                      className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ${errors.message ? "ring-red-400":"ring-gray-300"}  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#2D113E] sm:text-sm sm:leading-6`}
                  />
                                    {errors.message && <span className={"text-red-500 text-[12px]"}><sup>*</sup>{errors.message}</span>}

                                </div>
                            </div>
                        </div>
                        <div className="mt-8 flex justify-end">
                            <button
                                type="submit"
                                className="rounded-md bg-[#2D113E] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Send message
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )


}
export default Contact;