import {Container} from './Container';
import {useEffect, useRef, useState} from "react";
import {ACTIVEUSERS, COUNTRIES, INVOCATIONS} from "../constants";
import Lottie from "react-lottie-player";
import statsIll from '../images/stats.json'

export function Statistics() {

    const [focused, setFocused] = useState(false);
    const statsRef = useRef<HTMLDivElement>(null)
    const counterAnimation = () => {
        const speed = 100
        const torqCounters = document.getElementsByClassName('torq-stats-counter') as HTMLCollectionOf<HTMLDivElement>
        for (let i = -0; i < torqCounters.length; i++) {
            const counter = torqCounters[i];
            const updateCount = () => {
                const target = +counter.getAttribute('data-target');
                const count = +counter.innerText;
                let inc = 0;
                if ((target / speed) <= 1) {
                    inc = 1;
                } else {
                    inc = Math.floor(target / speed);
                }
                if (count < target) {
                    const res = count + inc
                    counter.innerText = res.toString();
                    setTimeout(updateCount, 1);
                } else {
                    counter.innerText = target.toString();
                }
            };

            updateCount();

        }
    }

    function resetCounters() {
        setFocused(false)
        const torqCounters = document.getElementsByClassName('torq-stats-counter') as HTMLCollectionOf<HTMLDivElement>
        for (let i = -0; i < torqCounters.length; i++) {
            torqCounters[i].innerText = "0";
        }
    }

    // Function to be called when the element becomes visible
    function handleIntersection(entries:IntersectionObserverEntry[], observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                resetCounters();
                setTimeout(()=>{
                    setFocused(true)
                    counterAnimation();
                },100)
                // If you want the animation to trigger only once, uncomment the line below
                // observer.unobserve(entry.target);
            }
        });
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };
        const observer = new IntersectionObserver(handleIntersection, options);
        observer.observe(statsRef.current);
    }, [])


    const StatsIllustration = () => {
        if (focused){
            return (
                <Lottie speed={2.5} play loop={false} animationData={statsIll}/>
            )
        }else {
            return <></>
        }
    }

    return (
        <section
            ref={statsRef}
            id="statistics"
            aria-label="Features for building a portfolio"
            className="py-20 relative sm:py-32"
        >
            <Container>
                <div className="grid gap-2 sm:text-center">
                    <h1 className={'grid text-4xl place-self-center'}>Data Insights</h1>
                    <h2 className={'grid text-gray-800 text-center'}>
                        Unleashing the Power of Statistics: Empowering Insights from Data.
                    </h2>
                    <div className={'grid h-fit md:h-52 grid-flow-row md:grid-flow-col gap-2'}>
                        <div className={'grid p-2'}>
                            <div
                                className={'grid cursor-pointer transition-all hover:bg-gray-300 grid-rows-[1fr_auto] rounded-lg bg-gray-200'}>
                                <div data-target={ACTIVEUSERS}
                                     className={'grid torq-stats-counter py-2 place-self-center text-5xl'}>
                                    0
                                </div>
                                <div className={'grid text-xl place-self-center py-2 lg:py-5 text-gray-900'}>
                                    active users
                                </div>
                            </div>
                        </div>
                        <div className={'grid p-2'}>
                            <div
                                className={'grid cursor-pointer transition-all hover:bg-gray-300 grid-rows-[1fr_auto] rounded-lg bg-gray-200'}>
                                <div data-target={INVOCATIONS}
                                     className={'grid torq-stats-counter py-2 place-self-center text-5xl'}>
                                    0
                                </div>
                                <div className={'grid text-xl place-self-center py-2 lg:py-5 text-gray-900'}>
                                    invocations
                                </div>
                            </div>
                        </div>
                        <div className={'grid p-2'}>
                            <div
                                className={'grid cursor-pointer transition-all hover:bg-gray-300 grid-rows-[1fr_auto] rounded-lg bg-gray-200'}>
                                <div data-target={COUNTRIES}
                                     className={'grid torq-stats-counter py-2 place-self-center text-5xl'}>
                                    0
                                </div>
                                <div className={'grid text-xl place-self-center py-2 lg:py-5 text-gray-900'}>
                                    countries
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <div className={'absolute hidden md:block w-full top-0 left-0 opacity-20 h-fit -z-[1]'}>
                <StatsIllustration/>
            </div>
        </section>
    );
}
