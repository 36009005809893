import {useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {Link} from "react-router-dom";

export function NavLinks() {
    let [hoveredIndex, setHoveredIndex] = useState(-1);

    return (
        <>
            {[
                ['Features', '#features'],
                ['Reviews', '#reviews'],
                ['Pricing', '#pricing'],
                ['FAQs', 'faq'],
                ["Contact", "contact"]
            ].map(([label, href], index) => (
                <Link
                    key={label}
                    to={"/" + href}
                    className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm hover:text-[#451165] text-gray-700 transition-colors delay-150 hover:delay-[0ms]"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(-1)}
                >
                    <AnimatePresence>
                        {hoveredIndex === index && (
                            <motion.span
                                className="absolute inset-0 rounded-lg bg-gray-100"
                                layoutId="hoverBackground"
                                initial={{opacity: 0}}
                                animate={{opacity: 1, transition: {duration: 0.15}}}
                                exit={{
                                    opacity: 0,
                                    transition: {duration: 0.15, delay: 0.2},
                                }}
                            />
                        )}
                    </AnimatePresence>
                    <span className="relative z-10">{label}</span>
                </Link>
            ))}
        </>
    );
}
