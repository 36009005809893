import {Container} from "./Container";
import dummy2 from '../images/discord-dummy2.json'
import community from '../images/discord-community.json'
import bg from '../images/mountain-7419960.svg'
import discordicon from '../images/discord.json'
import Lottie from "react-lottie-player";

export default function JoinDiscord() {
    const redirectToDiscordInvite =() => {
        window.open('https://discord.gg/5rfZ9CgM','_blank')
    }
    return (
        <section id={"join_discord"}
                 className={'grid relative h-[550px] bg-purple-950 overflow-hidden py-20 sm:pb-32 sm:pt-16 lg:pb-32 xl:pb-16'}>
            <Container className={'static z-10 grid grid-rows-[auto_1fr] max-w-[100vw]'}>
                <h2 className={'text-white grid place-self-center text-xl lg:text-6xl font-bold'}>Join our Discord community</h2>
                <div className={'grid'}>
                    <div className={'grid grid-rows-[auto_1fr] m-6 rounded-md'}>
                        <Lottie className={'grid w-[100px] lg:w-[200px] place-self-center'} play animationData={dummy2}/>
                        <div className={'grid gap-[30px]'}>
                            <div className={'grid leading-[1.8] w-fit text-center lg:w-[500px] place-self-center text-white'}>
                                {
                                    <>
                                        <div className={'grid text-lg lg:text-2xl font-bold'}>Connect with us on Discord!</div>
                                        <div className={'grid mt-[5px] text-lg'}>Join our community of AI enthusiasts.</div>
                                    </>
                                }
                            </div>
                            <div
                                onClick={redirectToDiscordInvite}
                                style={{boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}}
                                className={'grid cursor-pointer font-medium rounded-[50px] text-purple-950 text-md lg:text-lg w-fit h-fit bg-slate-100 px-10 py-3 justify-self-center'}>
                                Join our community
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <div className={'grid absolute top-0 left-0'}>
                <img src={bg} className={'grid h-[950px] w-screen object-cover aspect-[3/2] opacity-30'}/>
            </div>
            <div className={'grid absolute w-full h-full top-0 left-0'}>
                <Lottie className={'hidden lg:grid absolute right-0 bottom-0 w-[400px]'} play animationData={community}/>
                <Lottie play animationData={discordicon}
                        className={'hidden lg:grid absolute left-[30px] [transform:rotate(20deg)] bottom-0 w-[200px]'}/>
            </div>
        </section>
    )
}