import {Button} from './Button';

import React from 'react';
import useSendToStore from "../hooks/useSendToStore";

export function AppStoreLink({color = 'black', target}: { color: string, target: string }) {
    const {buttonRef, browser, url} = useSendToStore(target)


    function gtag_report_conversion(url) {
        const callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        // @ts-ignore
        window.gtag('event', 'conversion', {
            'send_to': 'AW-11163825224/e-mzCLeFi7AYEMjoqcsp',
            'event_callback': callback
        });
        return false;
    }



    return (
        <Button onClick={gtag_report_conversion} href={url}  ref={buttonRef} variant="outline" color={color} style={{color: color}}>
            {browser === 'edge-chromium' ? (
                <EdgeIcon className="h-6 w-6 flex-none"/>
            ) : (
                <ChromeIcon className="h-6 w-6 flex-none"/>
            )}
            <span className="ml-2.5">Download</span>
        </Button>
    );
}

function ChromeIcon(props: any) {
    return (
        <svg
            width="32"
            height="32"
            data-name="Layer 1"
            viewBox="0 0 32 32"
            {...props}
        >
            <path
                fill="#00ac47"
                d="M4.743 22.505a12.977 12.977 0 0010.137 6.444l5.885-10.193L16 16.006l-4.761 2.749-1.588-2.75-1.162-2.013-3.134-5.427a12.99 12.99 0 00-.612 13.94z"
            ></path>
            <path
                fill="#ea4435"
                d="M16 3.007A12.977 12.977 0 005.35 8.564l5.886 10.192L16 16.006v-5.499h11.766A12.99 12.99 0 0016 3.007z"
            ></path>
            <path
                fill="#ffba00"
                d="M27.256 22.505a12.977 12.977 0 00.512-12h-11.77v5.501l4.763 2.75-1.588 2.75-1.162 2.013-3.134 5.427a12.99 12.99 0 0012.379-6.441z"
            ></path>
            <circle cx="15.999" cy="16.007" r="5.5" fill="#fff"></circle>
            <circle cx="15.999" cy="16.007" r="4.25" fill="#4285f4"></circle>
        </svg>
    );
}

function EdgeIcon(props: any) {
    return (
        <svg ariaLabel="Edge" viewBox="0 0 512 512" {...props}>
            <rect width="512" height="512" fill="#fff" rx="15%"></rect>
            <radialGradient id="a" cx="0.6" cy="0.5">
                <stop offset="0.8" stopColor="#148"></stop>
                <stop offset="1" stopColor="#137"></stop>
            </radialGradient>
            <radialGradient id="b" cx="0.5" cy="0.6" fx="0.2" fy="0.6">
                <stop offset="0.8" stopColor="#38c"></stop>
                <stop offset="1" stopColor="#269"></stop>
            </radialGradient>
            <linearGradient id="c" y1="0.5" y2="1">
                <stop offset="0.1" stopColor="#5ad"></stop>
                <stop offset="0.6" stopColor="#5c8"></stop>
                <stop offset="0.8" stopColor="#7d5"></stop>
            </linearGradient>
            <path
                fill="url(#a)"
                d="M439 374c-50 77-131 98-163 96-191-9-162-262-47-261-82 52 30 224 195 157 17-12 20 3 15 8"
            ></path>
            <path
                fill="url(#b)"
                d="M311 255c18-82-31-135-129-135S38 212 38 259c0 124 125 253 287 203-134 39-214-116-146-210 46-66 123-68 132 3M411 99h1"
            ></path>
            <path
                fill="url(#c)"
                d="M39 253C51-15 419-30 472 202c14 107-86 149-166 115-42-26 26-20-3-99-48-112-251-103-264 35"
            ></path>
        </svg>
    );
}
