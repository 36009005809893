import React, {useEffect, useRef} from "react";
import designerdailyreport from '../images/designerdailyicon.png'
import bensbiteslogo from '../images/bensbiteslogo.jpeg'
import toolifyai from '../images/toolifyai.ico'
import ye from '../images/yeicon.ico'
import gptdemo from '../images/gptdemo.ico'
import fulixyz from '../images/fulixyz.ico'
import ixueme from '../images/ixueme.ico'
import aigurulogo from '../images/logo-aiguru-v2.svg'
import IndividualFeaturedButton from "./IndividualFeaturedButton";

export default function ScrollableFeaturedOn({className, parentRef}: {
    className: string;
    parentRef: React.MutableRefObject<HTMLDivElement>
}) {

    useEffect(() => {
        let scrollableElem = scrollableRef.current;
        let scrollWidth = 0;
        let displayedChild = -1;
        const setScrollInterval = () => {
            return setInterval(() => {
                if(parentRef.current && scrollableRef.current){
                    if (scrollWidth >= (parentRef.current.scrollWidth - parentRef.current.clientWidth) / 16) {
                        scrollWidth = 0
                        displayedChild = -1
                    } else {
                        displayedChild = displayedChild === -1 ? 0 : displayedChild
                        scrollWidth = scrollWidth + (scrollableRef.current.children[displayedChild].clientWidth / 16) + 1.5;
                    }
                    parentRef.current.scrollTo(scrollWidth * 16, 0)
                    displayedChild++;
                }
            }, 3000)
        }
        let intervalId = setScrollInterval();
        scrollableElem.addEventListener('mouseenter', () => {
            clearInterval(intervalId)
        })
        scrollableElem.addEventListener('mouseleave', () => {
            intervalId = setScrollInterval()
        })
        return () => {
            clearInterval(intervalId);
            scrollableElem.removeEventListener('mouseenter', () => {
                clearInterval(intervalId)
            })
            scrollableElem.removeEventListener('mouseleave', () => {
                intervalId = setScrollInterval()
            })
        }
    }, [])

    const scrollableRef = useRef<HTMLDivElement>(null)


    return (
        <div ref={scrollableRef} className={`flex ${className} flex-row gap-8 pr-[100px] justify-content-center`}>
            <a className={'grid place-self-center blur-[0.4px] transition hover:blur-0 hover:grayscale-0'}
               target="_blank" href="https://aiscout.net/listing/torq/%22" style={{width: "250px", height: "54px"}}><img
                src="https://aiscout.net/listings/36101/logo/" alt="Torq" style={{width: '250px', height: "54px"}}
                width="250" height="54"/></a>
            <IndividualFeaturedButton width={'170px'} href={'https://aitoolguru.com/tools/torq'}
                                      src={aigurulogo}
                                      companyfeatured={'AI Tool Guru'}/>
            <a className={'grid place-self-center blur-[0.4px] transition hover:blur-0 hover:grayscale-0'}
               href="https://www.futurepedia.io/tool/torq?utm_source=torq_embed"
               style={{width: "250px", height: "54px"}}>
                <img
                    src="https://www.futurepedia.io/api/image-widget?toolId=5d273a5e-2bd0-49c7-9790-033133e52cd4"
                    alt="Torq | Featured on Futurepedia" style={{width: "250px", height: "54px"}} width="250"
                    height="54"/>
            </a>
            <a target="_blank"
               className={'w-[180px] h-full blur-[0.4px] transition hover:blur-0 hover:grayscale-0'}
               href="https://betalist.com/startups/torq-ai?utm_campaign=badge-torq-ai&amp;utm_medium=badge&amp;utm_source=badge-featured">
                <img
                    alt="Torq AI - Your Ultimate Productivity Assistant | BetaList" className={"w-[180px] h-full"}
                    src = "https://betalist.com/badges/featured?id=97023&amp;theme=color"/>
            </a>
            <IndividualFeaturedButton width={'140px'} href={'https://www.toolify.ai/tool/torq-ai'}
                                      src={toolifyai}
                                      companyfeatured={'Toolify.ai'}/>
            <div className="featured blur-[0.4px] transition hover:blur-0 hover:grayscale-0" style={{
                width: "250px",
                borderRadius: "10px",
                border: "0.8px solid gray",
                textAlign: "center",
                userSelect: "auto",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            }}>
                <a href="https://whattheai.tech/tools/torq/" className="block"
                   style={{width: "250px", height: "54px"}} target="_blank">
                    <img src="https://whattheai.tech/wp-content/uploads/2023/03/featured-on.png"
                         alt=" | Featured on WhatTheAI" style={{
                        width: "260px",
                        "height": "55px",
                        userSelect: "auto",
                        paddingTop: "12px",
                        paddingBottom: "8px",
                        paddingLeft: "5px",
                        paddingRight: "5px"
                    }} width="270" height="54"/>
                </a>
            </div>
            <IndividualFeaturedButton width={'250px'} href={'https://app.designerdailyreport.com/'}
                                      src={designerdailyreport}
                                      companyfeatured={'Designer Daily Report'}/>
            <IndividualFeaturedButton width={'150px'} href={'https://www.gptdemo.net/en/tool/torq'} src={gptdemo}
                                      companyfeatured={`GPT Demo`}/>
            <IndividualFeaturedButton width={'130px'} href={'https://www.yeeach.com/'} src={ye}
                                      companyfeatured={'Yeeach'}/>
            <IndividualFeaturedButton width={'170px'} href={'https://news.bensbites.co/search?q=torq'}
                                      src={bensbiteslogo}
                                      companyfeatured={`BEN'S BITES`}/>
            <IndividualFeaturedButton width={'130px'} href={'https://1fuli.xyz/26158/'} src={fulixyz}
                                      companyfeatured={`1fuli.xyz`}/>
            <IndividualFeaturedButton width={'130px'} href={'https://ixue.me/2023/06/26/18149'} src={ixueme}
                                      companyfeatured={`ixue.me`}/>
            <a className={'w-[250px] h-full blur-[0.4px] transition hover:blur-0 hover:grayscale-0'}
               href="https://foundr.ai/product/torq"><img src="https://foundr.ai/embed/torq"
                                                          style={{height: "100%", width: "250px"}}/></a>

        </div>
)
}