import {Disclosure} from '@headlessui/react';
import {MinusSmallIcon, PlusSmallIcon} from '@heroicons/react/24/outline';
import {faqs} from '../constants';
import {useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import faqIllustration from "../images/faq_lottie.json";
import Lottie from "react-lottie-player";

export default function Faqs() {
    const location = useLocation();
    const [openedAccordId, setOpenAccordId] = useState(location.hash);
    useEffect(() => {
        if (location.hash) {
            setOpenAccordId(location.hash);
        }
    }, [location]);

    const style = {
        height: 500,
    };

    function FAQLottie(){
        return <Lottie style={style} play animationData={faqIllustration}/>
    }

    return (
        <section id="faq" className="bg-white">
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
                <div className="grid">
                    <div className={'grid grid-flow-col gap-[8px] place-self-center w-fit'}>
                        <h2 className="text-2xl lg:text-4xl font-bold leading-10 tracking-tight text-gray-900">
                            Frequently asked questions
                        </h2>
                    </div>
                    <div className={'grid grid-cols-[auto_1fr] gap-[20px]'}>
                        <div className={'w-fit place-self-center hidden lg:grid'}>
                            <FAQLottie/>
                        </div>
                        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                            {faqs.map((faq) => (
                                <Disclosure
                                    defaultOpen={openedAccordId === '#faq_' + faq.id}
                                    as="div"
                                    key={faq.id}
                                    id={'faq_' + faq.id}
                                    className="pt-6"
                                >
                                    {({open}) => (
                                        <>
                                            <dt>
                                                <Disclosure.Button
                                                    className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                                                    <span className="ml-6 flex h-7 items-center">
                          {open ? (
                              <MinusSmallIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                              />
                          ) : (
                              <PlusSmallIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                              />
                          )}
                        </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-base leading-7 text-gray-600">
                                                    {faq.answer}
                                                </p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </section>
    );
}
