export const reviews = [
    {
        "id": "",
        "title": "Exceptional Extension for Text Enhancement and Translation",
        "body": "This browser extension is a game-changer! It enhances text, provides accurate translations, and even drafts emails effortlessly. The integrated responses on Google search are a huge plus. A must-have tool for seamless writing and communication.",
        "author": "Olivia Nakamura",
        "rating": 5,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Effortless Multilingual Text Improvement",
        "body": "With this extension, enhancing, correcting, and rephrasing text becomes effortless. The integrated responses on Google search page and the email drafting feature are remarkable. Highly recommended for precise and efficient language assistance.",
        "author": "Mohammed Khan",
        "rating": 4,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Enhance and Translate with Ease",
        "body": "This browser extension seamlessly enhances, corrects, and translates text from the current tab. The integrated responses on Google search make it a powerful tool. Efficient email drafting and intent detection make communication a breeze.",
        "author": "Chihiro Yamamoto",
        "rating": 5,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Boost Your Writing Skills with Precision",
        "body": "Boost your writing skills with this extension. It offers text enhancement, accurate translations, and rephrasing suggestions. The integrated responses on Google search and email drafting capabilities are impressive. A valuable tool for writers.",
        "author": "Alexandre Silva",
        "rating": 4,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Seamless Language Assistance at Your Fingertips",
        "body": "This browser extension provides seamless language assistance. Enhance, translate, and correct text easily. The integrated responses on Google search and email drafting feature are excellent. A reliable tool for efficient communication.",
        "author": "Sofia Petrova",
        "rating": 5,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Efficient Writing Aid with Translation and Enhancement",
        "body": "Efficiently enhance and translate text with this browser extension. It offers accurate corrections, rephrasing suggestions, and integrated responses on Google search. The email drafting and intent detection features are impressive. Highly recommended.",
        "author": "Liam O'Connor",
        "rating": 4,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Master Multilingual Communication with Ease",
        "body": "Master multilingual communication effortlessly with this extension. Enhance, correct, and translate text seamlessly. Integrated responses on Google search and email drafting make it a comprehensive tool. Highly recommended for language enthusiasts.",
        "author": "Elena Costa",
        "rating": 5,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Enhance, Translate, and Communicate Like a Pro",
        "body": "Enhance, translate, and communicate like a pro with this browser extension. It offers accurate corrections, rephrasing suggestions, and integrated responses on Google search. The email drafting and intent detection features are invaluable.",
        "author": "Rajesh Patel",
        "rating": 4,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Effortless Text Enhancement and Translation",
        "body": "Effortlessly enhance, correct, and translate text with this extension. Integrated responses on Google search and email drafting feature provide a seamless experience. A reliable tool for precise communication and writing improvement.",
        "author": "Aya Nakamura",
        "rating": 5,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Enhance and Translate Text with Precision",
        "body": "Enhance, correct, and translate text with precision using this browser extension. Integrated responses on Google search and email drafting make it an exceptional tool. Highly recommended for efficient communication and language enhancement.",
        "author": "Gabriel Fernandez",
        "rating": 4,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Streamline Writing and Communication Effortlessly",
        "body": "Streamline your writing and communication with this extension. Enhance, translate, and correct text seamlessly. Integrated responses on Google search and email drafting feature make it a must-have tool for efficient language assistance.",
        "author": "Marta Kowalska",
        "rating": 5,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Efficient Text Enhancement and Translation Solution",
        "body": "Efficiently enhance, correct, and translate text with this browser extension. Integrated responses on Google search and email drafting feature make it a comprehensive solution. Highly recommended for accurate and seamless language assistance.",
        "author": "Akio Tanaka",
        "rating": 4,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Elevate Your Writing with Seamless Assistance",
        "body": "Elevate your writing with seamless assistance. This extension enhances, corrects, and translates text flawlessly. The integrated responses on Google search and email drafting feature are exceptional. A valuable tool for effective communication.",
        "author": "Maria da Silva",
        "rating": 5,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Effortless Multilingual Text Enhancement",
        "body": "Enhance, correct, and translate text effortlessly with this extension. The integrated responses on Google search and email drafting feature are remarkable. Highly recommended for precise and efficient language assistance.",
        "author": "Takumi Nakamura",
        "rating": 4,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Seamless Writing and Communication Tool",
        "body": "This extension seamlessly enhances, corrects, and translates text from the current tab. The integrated responses on Google search make it a powerful tool. Efficient email drafting and intent detection make communication a breeze.",
        "author": "Cristina Gonzalez",
        "rating": 5,
        "url": "",
        "profilepicture": ""
    },
    {
        "id": "",
        "title": "Boost Your Language Skills with Precision",
        "body": "Boost your language skills with this extension. It offers text enhancement, accurate translations, and rephrasing suggestions. The integrated responses on Google search and email drafting capabilities are impressive. A valuable tool for language enthusiasts.",
        "author": "Filippo Rossi",
        "rating": 4,
        "url": "",
        "profilepicture": ""
    },
    {
        "url":"https://www.producthunt.com/posts/torq-ai?comment=2513878",
        "id": "producthunt",
        "rating": 5,
        "author":"Rahul Choudhary",
        "title": "Just started using Torq AI, and it's already transforming my workflow.",
        "body": "The AI-powered quick replies in Gmail are a lifesaver. Seamless browsing and streamlined data access have boosted my productivity to new heights. This Chrome extension is a must-have! #ProductivityBoosted #TorqAI",
        "profilepicture": "https://ph-avatars.imgix.net/5755258/98e8c796-cde6-4ef4-814c-d290480fcfef?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
    },
    {
        "url":"https://www.producthunt.com/posts/torq-ai?comment=2514274",
        "id": "producthunt",
        "rating": 5,
        "author":"Nandita",
        "title": "The Torq AI Chrome extension has made replying to emails a breeze.",
        "body": "Its AI-powered suggestions help me find the right words and tone, ensuring my messages are concise and effective. It's definitely a time-saver and has improved the overall quality of my email communication I've been using the Torq AI Chrome extension for a few weeks now, and I'm impressed with its intelligent features. It helps me stay organized, find relevant information quickly, and saves me a lot of time. It's become an essential part of my daily workflow.\n" +
            "#TorqAI #BestTool",
        "profilepicture": "https://ph-avatars.imgix.net/5755868/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
    },
    {
        "url":"https://www.producthunt.com/posts/torq-ai?comment=2513986",
        "id": "producthunt",
        "rating": 5,
        "author":"Shay",
        "title": "Congrats on the launch! 🎉",
        "body": "This is amazing, it can cut down so much time on drafting your emails! Does it work with any email service, or is it limited to Gmail? Because I'm also working on our own collaborative email service, Zapmail.app , we are actually launching next week! and would love to see how we can use Torq AI with it.",
        "profilepicture": "https://ph-avatars.imgix.net/5329671/e223d801-6e8c-431a-9f85-907a88c1e02b?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
    },
    {
        "url":"https://www.producthunt.com/posts/torq-ai?comment=2513854",
        "id": "producthunt",
        "rating": 5,
        "author":"Deepak Singh",
        "title": "Torq AI is a game-changer!",
        "body": "The swift AI-driven responses in Gmail are an absolute timesaver. The ease of data access is next level! Kudos to the team for making productivity a seamless experience. #LovingTorqAI",
        "profilepicture": "https://ph-avatars.imgix.net/5714868/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
    },
    {
        "url":"https://www.producthunt.com/posts/torq-ai?comment=2514107",
        "id": "producthunt",
        "rating": 5,
        "author":"Carrienul",
        "title": "Congratulations!",
        "body": "Congrats on bringing your new product to market. I can't wait to see what comes next for you!",
        "profilepicture": "https://ph-avatars.imgix.net/5472553/2ad775c3-48ac-41fd-9799-ae2b68e53e7b?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=2"
    },
    {
        "url":"",
        "id": "producthunt",
        "rating": 5,
        "author":"Bablu Singh",
        "title": "Torq AI is a game-changer!",
        "body": "Its intelligent and user-friendly interface makes productivity effortless. The seamless integrations and text processing enhance task management. Torq AI is a must-have tool for reclaiming time. It is highly recommended!",
        "profilepicture": "https://ph-avatars.imgix.net/4997968/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
    },
    {
        "url":"",
        "id": "producthunt",
        "rating": 5,
        "author":"Chitwan Bindal",
        "title": "This Chrome extension has revolutionized the way I work.",
        "body": "The email drafting and replying features are incredibly efficient, saving me valuable time and ensuring my messages are polished and professional. The ability to select text and instantly receive translations, rephrased versions, and grammar corrections are a lifesaver for my writing tasks. The intuitive interface and seamless integration make Torq AI a must-have tool for anyone looking to enhance their productivity. Five stars all the way!",
        "profilepicture": "https://ph-avatars.imgix.net/5755244/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2"
    },
];
