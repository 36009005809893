import React from 'react';
import {Navigate} from "react-router-dom";

const UnknownRoute: React.FC = () => {
    return (
        <Navigate to={"/not-found"}></Navigate>
    );
};

export default UnknownRoute;
