import {useEffect} from "react";

export default function Support(){

    useEffect(()=>{
        const script = document.createElement("script")
        script.src="https://tally.so/widgets/embed.js"
        document.body.appendChild(script)
    },[])

    return(
        <div className={'grid w-screen h-screen'}>
            <iframe data-tally-src="https://tally.so/r/wQeo5X?transparentBackground=1" width="100%" height="100%"
                    frameBorder="0" marginHeight={0} marginWidth={0} title="Report Issue"></iframe>
        </div>
    )
}