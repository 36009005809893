import {Navigate} from "react-router-dom";
import React, {useEffect} from "react";
import mixpanel from "mixpanel-browser";
import {INFOBJECT} from "../types";


const Uninstall = ({setInfoObject}: { setInfoObject: React.Dispatch<React.SetStateAction<INFOBJECT>> }) => {
    const id = new URLSearchParams(window.location.search).get("id");
    const isValidUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id);

    useEffect(() => {
        if (isValidUUID) {
            const script = document.createElement("script")
            script.src="https://tally.so/widgets/embed.js"
            document.body.appendChild(script)
            mixpanel.track("Uninstall", {
                id: id
            })
        }
    }, [])
    if (!isValidUUID) {
        return <Navigate to="/not-found" replace/>;
    }

    return(
        <>
            <iframe data-tally-src={`https://tally.so/r/3qDZeG?transparentBackground=1`} className={"w-screen h-screen"}
                    frameBorder="0" marginHeight={0} marginWidth= {0} title="We're sad to see you go!"></iframe>
        </>
    )
}
export default Uninstall;