import {INFOBJECT} from "../types";
import React from "react";

export default function Notifications({infoObject,setInfoObject}:{infoObject:INFOBJECT;setInfoObject:React.Dispatch<React.SetStateAction<INFOBJECT>>}){
    return(
        infoObject ? (
            <div className={'absolute z-[2147483647] cursor-pointer top-7 right-[20%] w-fit rounded-l-lg rounded-r-lg md:rounded-r-none md:rounded-l-lg h-fit md:top-24 md:right-0 md:w-[400px] md:h-fit shadow-md bg-[#9463b2] border-t-4 border-[#3C1355]'}>
                <div className={'grid grid-flow-col gap-[8px] md:gap-[10px] p-[8px] md:p-[12px]'}>
                    <div className={'grid place-content-center'}>
                        <svg className="fill-white w-4 h-4 md:h-6 md:w-6 text-teal-500" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20">
                            <path
                                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                        </svg>
                    </div>
                    <div className={'grid text-white text-[11px] md:text-[16px] w-full h-full place-self-center'}>
                        <div className={'grid place-content-center'}>
                            {infoObject.info}
                        </div>
                        {
                            infoObject.redirectTime!== 0&&(
                                <div className={'grid place-content-center'}>
                                    Redirecting
                                </div>
                            )
                        }
                    </div>
                </div>
                <div onClick={() => {
                    setInfoObject(null);
                }} className={'grid absolute -top-1 right-0 w-4 h-4'}>
                    x
                </div>
            </div>
        ):<></>
    )
}