import {Popover} from '@headlessui/react';
import {AnimatePresence, motion} from 'framer-motion';
import {Container} from './Container';
import {Logo} from './Logo';
import {NavLinks} from './NavLinks';
import {Link, useNavigate} from 'react-router-dom';

function MenuIcon(props: any) {
    return (
        <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
            <path
                d="M5 6h14M5 18h14M5 12h14"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function ChevronUpIcon(props: any) {
    return (
        <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
            <path
                d="M17 14l-5-5-5 5"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function MobileNavLink({children, ...props}: any) {
    return (
        <Popover.Button
            as={Link}
            className="block text-base leading-7 tracking-tight text-gray-700"
            {...props}
        >
            {children}
        </Popover.Button>
    );
}

export function Header() {
    const navigate = useNavigate()
    const goToSubscribe = (e) => {
        navigate("/#subscribe-to-newsletter")
    }
    return (
        <header>
            <nav>
                <Container className="relative z-50 flex justify-between py-8">
                    <div className="relative z-10 flex items-center gap-16">
                        <Link to="/" aria-label="Home">
                            <div className="grid lg:grid-flow-col lg:gap-[10px]">
                                <Logo className="h-10 w-auto"/>
                                <h1 className="text-xl lg:grid place-content-center hidden font-medium tracking-tight text-gray-900">
                                    TORQ AI
                                </h1>
                            </div>
                        </Link>
                        <div className="hidden lg:flex lg:gap-10">
                            <NavLinks/>
                        </div>
                    </div>
                    <div className="flex items-center gap-6">
                        <Popover className="lg:hidden">
                            {({open}) => (
                                <>
                                    <Popover.Button
                                        className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 [&:not(:focus-visible)]:focus:outline-none"
                                        aria-label="Toggle site navigation"
                                    >
                                        {({open}) =>
                                            open ? (
                                                <ChevronUpIcon className="h-6 w-6"/>
                                            ) : (
                                                <MenuIcon className="h-6 w-6"/>
                                            )
                                        }
                                    </Popover.Button>
                                    <AnimatePresence initial={false}>
                                        {open && (
                                            <>
                                                <Popover.Overlay
                                                    static
                                                    as={motion.div}
                                                    initial={{opacity: 0}}
                                                    animate={{opacity: 1}}
                                                    exit={{opacity: 0}}
                                                    className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                                                />
                                                <Popover.Panel
                                                    static
                                                    as={motion.div}
                                                    initial={{opacity: 0, y: -32}}
                                                    animate={{opacity: 1, y: 0}}
                                                    exit={{
                                                        opacity: 0,
                                                        y: -32,
                                                        transition: {duration: 0.2},
                                                    }}
                                                    className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                                                >
                                                    <div className="space-y-4">
                                                        <MobileNavLink to={"/#features"}>
                                                            Features
                                                        </MobileNavLink>
                                                        <MobileNavLink to="/#reviews">
                                                            Reviews
                                                        </MobileNavLink>
                                                        <MobileNavLink to="/#pricing">
                                                            Pricing
                                                        </MobileNavLink>
                                                        <MobileNavLink to="/faq">FAQs</MobileNavLink>
                                                        <MobileNavLink to="/contact">Contact</MobileNavLink>
                                                    </div>
                                                </Popover.Panel>
                                            </>
                                        )}
                                    </AnimatePresence>
                                </>
                            )}
                        </Popover>
                        <button onClick={goToSubscribe}
                            className={'inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors bg-[#2D113E] text-white active:text-white/80 md:block'}>
                            Subscribe
                        </button>
                        {/*<Button ref={buttonRef} href={url} target="_blank" className="hidden md:block" color="torqSolid">*/}
                        {/*    Download*/}
                        {/*</Button>*/}
                    </div>
                </Container>
            </nav>
        </header>
    );
}
