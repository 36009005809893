import { AppStoreLink } from './AppStoreLink';
import { CircleBackground } from './CircleBackground';
import { Container } from './Container';
import Chain from "./Chain";
import {Link} from "react-router-dom";
import CircleGradient from "./CircleGradient";

export function CallToAction() {
  return (
    <section
      id="get-free-today"
      className="relative overflow-hidden bg-gray-900 isolate py-20 sm:py-28"
    >
      <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
        <CircleBackground color="#fff" className="animate-spin-slower" />
      </div>
      <Container className="relative">
        <div className="mx-auto max-w-md sm:text-center">
          <div className={'grid grid-flow-col gap-[4px] text-white'}>
            <Chain/>
            <Link to={'#get-free-today'}>
              <h2 className="text-3xl font-medium tracking-tight text-white sm:text-4xl">
                Want to increase your productivity.
              </h2>
            </Link>
          </div>
          <p className="mt-4 text-lg text-gray-300">
            It takes seconds to install. Download our chrome extension and
            increase your productivity to next level.
          </p>
          <div className="mt-8 flex justify-center">
            <AppStoreLink target={'cta'} color="white" />
          </div>
        </div>
      </Container>
      <CircleGradient/>
    </section>
  );
}
