import React, {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Dialog, Transition} from "@headlessui/react";
import useBrowserType from "../hooks/useBrowserType";
import mixpanel from "mixpanel-browser";
import axios from "axios";
import CircleGradient from "./CircleGradient";

export default function SubscribeNewsletter() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState("");

    const {browser} = useBrowserType()

    function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setInputValue(e.target.value)
    }

    function subscribeNewsLatter(e) {
        e.preventDefault()
        mixpanel.track("Subscribe",{
            email:inputValue
        })
        showPopup();
        axios.post("https://49ac09bnu8.execute-api.us-east-1.amazonaws.com/prod/subscribe", {
            "email_id": inputValue
        }, {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        })
            .then(
                (res) => {
                    console.log(res);
                    mixpanel.track('User Subscribed',{
                        browser:browser,
                        email: inputValue
                    })

                })
            .catch(
                (err) =>
                    console.log(err)
            )
    }
    function showPopup() {
        openModal();
    }
    function closeModal() {
        setIsOpen(false);
    }
    function openModal() {
        setIsOpen(true);
    }
    const privacyPageClickHandler = () => {
        navigate("/privacy");
    }
    return (
        <section id={"subscribe-to-newsletter"} className="bg-white py-16 sm:py-24">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
                    <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Want product news and updates? Sign up for our newsletter.
                    </h2>
                    <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">
                        We care about your data. Read our{' '}
                        <a
                            onClick={privacyPageClickHandler}
                            className={'font-semibold underline cursor-pointer hover:text-gray-500'}
                        >
                            privacy&nbsp;policy
                        </a>
                        .
                    </p>
                    <form onSubmit={subscribeNewsLatter} className="mx-auto mt-10 flex max-w-md gap-x-4">
                        <label htmlFor="email-address" className="sr-only">
                            Email address
                        </label>
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            onChange={onChangeHandler}
                            autoComplete="email"
                            required
                            className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                            placeholder="Enter your email"
                        />
                        <button
                            type="submit"
                            className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                            Notify me
                        </button>
                    </form>
                    <CircleGradient/>
                </div>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-xl font-medium leading-6 text-gray-900"
                                    >
                                        Subscription successful 🎉
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-md text-gray-500">
                                            Welcome aboard! Get ready for exclusive updates, exciting
                                            offers, and a world of inspiration.😉
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Got it, thanks!
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </section>
    )
}