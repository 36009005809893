const Privacy = () => {
    return (
        <div
            className="relative text-gray-700 "
        >
            <div className="container mx-auto py-8 px-8">
                <h1 className="text-4xl font-bold mb-6">Privacy Policy</h1>
                <h6 className="text-xl mb-6">Last Updated on 20 Jun 2023</h6>
                <p className="mb-4">At Torq ("we," "us," or "our"), we value your privacy and are committed to
                    protecting it through our compliance with this privacy policy ("Policy"). This Policy
                    describes our practices regarding data collection, usage, and disclosure when you use
                    the Torq Chrome extension ("Extension").</p>

                <p className="mb-4">Please read this Policy carefully to understand our policies and practices
                    regarding your information and how we will treat it. By installing or using the Torq
                    Chrome Extension, you agree to this Policy. If you do not agree with our policies and
                    practices, your choice is not to use our Extension.</p>

                <p className="mb-4">This Policy may change from time to time. We will notify you of any changes
                    by updating the "Last updated" date at the top of this Policy. Your continued use of the
                    Torq Chrome Extension after we make changes is deemed to be acceptance of those changes,
                    so please check the Policy periodically for updates.</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">1. Information We Collect and How We Collect
                    It</h2>
                <p className="mb-4">As the developer of the Torq Chrome Extension, we do not collect any data
                    from you. Our Extension is designed to operate solely within the Google Chrome browser
                    on your device, and we do not access, store, or transmit any information about you or
                    your device.</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">2. No Data Transmission</h2>
                <p className="mb-4">We do not send any data out of the Google Chrome browser on your device. The
                    Torq Chrome Extension operates locally on your device and does not communicate with our
                    servers or any third-party servers.</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">3. Third-Party Links</h2>
                <p className="mb-4">Our Extension may contain links to third-party websites, applications, or
                    services that are not operated by us. If you click on a third-party link, you will be
                    directed to that third party's site. We strongly advise you to review the privacy policy
                    of every site you visit.</p>

                <p className="mb-4">We have no control over and assume no responsibility for the content,
                    privacy policies, or practices of any third-party sites or services.</p>

                <h4 className="text-xl font-bold mt-8 mb-1"> Contact Us</h4>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>

                <p>Torq Inc</p>
                <p>India</p>
                <p>Email: connect@torq.live</p>

            </div>
        </div>
    )

}
export default Privacy;