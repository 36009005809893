import React, {useEffect, useState} from 'react';
import {Header} from './components/Header';
import {Hero} from './components/Hero';
import {PrimaryFeatures} from './components/PrimaryFeatures';
import {Statistics} from './components/Statistics';
import {CallToAction} from './components/CallToAction';
import {Reviews} from './components/Reviews';
import {Pricing} from './components/Pricing';
import Faqs from './components/Faqs';
import Footer from './components/Footer';
import mixpanel from 'mixpanel-browser';
import {v4} from "uuid";
import {Route, Routes, useLocation} from "react-router-dom";


import Privacy from "./components/Privacy";
import Contact from "./components/contact";
import Uninstall from "./components/Uninstall";
import NotFound from "./components/NotFound";
import UnknownRoute from "./components/UnknownRoute";
import Notifications from "./components/Notifications";
import {INFOBJECT} from "./types";
import FeaturedOn from "./components/FeaturedOn";
import JoinDiscord from "./components/JoinDiscord";
import GettingStarted from "./components/GettingStarted";
import SubscribeNewsletter from "./components/SubscribeNewsletter";
import GlobalFlag from "./components/GlobalFlag";
import Support from "./components/Support";


export const TORQ_MIXPANEL_USER_IDENTITY = "trq-mixpanel-usr-id"

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!, {
    debug: true,
    ignore_dnt: true,
    api_host: "https://proxy-mix-2.torq.live"
});

function App() {
    const location = useLocation()
    if (location.pathname === '/feedback') {
        console.log("uninstall")
        const id = new URLSearchParams(window.location.search).get("id");
        const isValidUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id);
        if (id && isValidUUID) {
            console.log("valid")
            mixpanel.identify(id);
        }
    } else {
        if (!localStorage.getItem(TORQ_MIXPANEL_USER_IDENTITY)) {
            localStorage.setItem(TORQ_MIXPANEL_USER_IDENTITY, v4());
        }
        mixpanel.identify(localStorage.getItem(TORQ_MIXPANEL_USER_IDENTITY) || v4());
    }
    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {
            window.scrollTo({top: 0, left: 0, behavior: "smooth"})
        }
    }, [location,])

    useEffect(() => {
        window.addEventListener('load', () => {
            const sectionId = window.location.hash.slice(1);
            const section = document.getElementById(sectionId);

            if (section) {
                section.scrollIntoView({behavior: 'smooth'});
                if (sectionId.startsWith('faq')) {
                    console.log("faq visited")
                    mixpanel.track('FAQ visited');
                }
            }
        });
    }, [document]);
    useEffect(() => {
        mixpanel.track('Website Loaded')
        if (location.search) {
            if (location.search.split("?")[1]) {
                const ref = location.search.split("?")[1].split("ref=");
                mixpanel.track("Website visited", {
                    description: ref[1]
                })
            }
        }
    }, [])

    const hideHeaderAndFooter = (location.pathname === '/not-found' || location.pathname === '/feedback' || location.pathname === "/support");

    const [infoObject, setInfoObject] = useState<INFOBJECT | null>(null);

    return (
        <>
            {!hideHeaderAndFooter && <Header/>}
            {!hideHeaderAndFooter && <Notifications infoObject={infoObject} setInfoObject={setInfoObject}/>}
            <Routes>
                <Route path="/" element={
                    <main>
                        <Hero/>
                        <FeaturedOn/>
                        <PrimaryFeatures/>
                        <Statistics/>
                        <CallToAction/>
                        <Reviews/>
                        <Pricing/>
                        <JoinDiscord/>
                        <SubscribeNewsletter/>
                    </main>
                }/>
                <Route path="privacy" element={<Privacy/>}/>
                <Route path="contact" element={<Contact setInfoObject={setInfoObject}/>}/>
                <Route path="/feedback" element={<Uninstall setInfoObject={setInfoObject}/>}/>
                <Route path={"faq"} element={<Faqs/>}/>
                <Route path="/not-found" element={<NotFound/>}/>
                <Route path={"getting-started"} element={<GettingStarted/>}/>
                <Route path={"support"} element={<Support/>}/>
                <Route path="/*" element={<UnknownRoute/>}/>

            </Routes>
            {!hideHeaderAndFooter && (
                <>
                    <Footer/>
                </>
            )}
        </>
    );
}

export default App;
